import { RouteRecordRaw } from 'vue-router';
import { systemNotice } from '@/router/mobile/modules/systemNotice';
import { userCenter } from '@/router/mobile/modules/userCenter';
import { business } from '@/router/mobile/modules/business';

export const mobile: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'mobile',
        redirect: 'login',
        component: () => import('@/pages/Index.vue'),
        children: [
            // 登录页
            {
                path: '/login',
                name: 'login',
                component: () => import('@/pages/mobile/login/login/Login.vue'),
                meta: {
                    title: '登录'
                }
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/pages/mobile/register/register/Register.vue'),
                meta: {
                    title: '注册'
                }
            },
            {
                path: '/register-success',
                name: 'register-success',
                component: () =>
                    import('@/pages/mobile/register/register-success/RegisterSuccess.vue'),
                meta: {
                    title: '注册成功'
                }
            },
            {
                path: '/reset-password',
                name: 'reset-password',
                component: () => import('@/pages/mobile/login/reset-password/ResetPassword.vue'),
                meta: {
                    title: '重置密码'
                }
            },
            {
                path: '/reset-password-input',
                name: 'reset-password-input',
                component: () =>
                    import('@/pages/mobile/login/reset-password/ResetPasswordInput.vue'),
                meta: {
                    title: '重置密码-修改'
                }
            },
            {
                path: '/update-password',
                name: 'update-password',
                component: () => import('@/pages/mobile/login/update-password/UpdatePassword.vue'),
                meta: {
                    title: '修改密码'
                }
            },
            {
                path: '/update-success',
                name: 'update-success',
                component: () => import('@/pages/mobile/login/update-success/UpdateSuccess.vue'),
                meta: {
                    title: '修改密码'
                }
            },
            {
                path: '/:pathMatch(.*)',
                name: '404',
                component: () => import('@/pages/mobile/not-found/404.vue'),
                meta: {
                    title: '404 Not Found'
                }
            },
            ...systemNotice,
            ...userCenter,
            ...business
        ]
    }
];
