import useRouterCommon from '@/hooks/useRouterCommon';
import useStore from '@/pinia';
import {
    postQueryUserListHttp,
    postSwitchUserHttp
} from '@/common/http/request/request-method/user-center/userCenter';

const usePersonCenter = (flag = 'pc') => {
    const { userInfo } = useStore();
    const { push, go } = useRouterCommon();
    const { mobile, username } = userInfo.getUserInfo;

    const userList = computed(() => userInfo.getUserList);

    const menu = [
        {
            id: 1,
            name: '修改密码',
            icon: 'icon-mima',
            to: '/user-center/update-password-inner'
        },
        {
            id: 2,
            name: '修改手机',
            icon: 'icon-shouji',
            to: '/user-center/update-phone-inner'
        },
        {
            id: 3,
            name: '修改账户信息',
            icon: 'icon-bianji1',
            to: '/user-center/update-info-inner'
        }
    ];

    const queryUserList = async () => {
        try {
            if (!mobile) return;
            const { data } = await postQueryUserListHttp({
                mobile
            });
            await userInfo.updateUserList(data);
        } catch (e) {
            console.log(e);
        }
    };

    const switchUser = async (item: string) => {
        if (item === username) return;
        const { data } = await postSwitchUserHttp({
            appId: import.meta.env.VITE_APP_ID,
            username: item
        });

        await userInfo.updateToken({
            ...data
        });

        go(0);
    };

    return {
        userInfo,
        queryUserList,
        go,
        push,
        userList,
        menu,
        username,
        switchUser
    };
};

export default usePersonCenter;
