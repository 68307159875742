export class RequestPaths {
    // 通用
    readonly common = {
        captchaCode: '/out/user/getCaptcha',
        sms: '/out/user/sendSmsCode',
        refreshToken: '/out/user/refreshToken',
        emailCode: '/out/user/sendEmailCode',
        camera: '/out/business/applyAuth/ocrImageAuthCode'
    };

    // 登录
    readonly loginApi = {
        login: '/out/user/login',
        loginOut: '/out/user/logout',
        resetPwdByPhone: '/out/user/retrieveSmsLoginInfo',
        resetPwdByEmail: '/out/user/retrieveLoginInfo',
        updatePwd: '/out/user/updateUserPwdWhenExpired'
    };

    // 注册
    readonly registerApi = {
        register: '/out/user/register'
    };

    // 业务功能
    readonly businessApi = {
        queryNT: '/out/business/nt/query',
        queryFileNT: '/out/business/nt/queryFileInfoByPackage',
        queryReport: '/out/business/report/page',
        reportExport: '/out/business/report/export',
        downloadAuthList: '/out/business/infoDown/page',
        attach: '/out/business/infoDown/attach',
        downloadAuthBatch: '/out/business/infoDown/down',
        // 申请授权
        applyAuthList: '/out/business/applyAuth/query',
        addCodes: '/out/business/applyAuth/verifyApplyAuthCode',
        queryApplyFormInfo: '/out/business/applyAuth/queryTempByAuthCode',
        cscLov: '/out/business/applyAuth/cscLov',
        fgSubmit: '/out/business/applyAuth/multiFileUploadSubmit',
        noFgSubmit: '/out/business/applyAuth/applyAddSaveSubmit',
        deleteCode: '/out/business/applyAuth/delete',
        save: '/out/business/applyAuth/tempSaveAuthCode'
    };

    // 用户中心
    readonly userCenterApi = {
        updateUserInfo: '/out/user/updateUserInfo',
        queryUserList: '/out/user/queryMobileUserList',
        queryUserInfo: '/out/user/queryUserInfo',
        updateMobileByPwd: '/out/user/updateUserMobile',
        updateMobileBySms: '/out/user/updateUserMobileBySms',
        switchUser: '/out/user/transferUser',
        checkOriginMobile: '/out/user/checkOriginUserMobile',
        checkOriginPwd: '/out/user/checkOriginPassword'
    };

    // 系统通知
    readonly systemNoticeApi = {
        queryNotice: '/out/notice/list'
    };
}
