import Layout from '@/pages/layout/Layout.vue';

export const systemNotice = [
    {
        path: '/system-notice',
        component: Layout,
        name: 'system-notice',
        meta: {
            title: '主页',
            parentMenu: '/'
        },
        redirect: '/system-notice/list',
        children: [
            {
                path: 'list',
                name: 'list',
                component: () => import('@/pages/pc/system-notice/list/List.vue'),
                meta: {
                    title: '系统通知',
                    parentMenu: '/system-notice'
                }
            },
            {
                path: 'detail',
                name: 'detail',
                component: () => import('@/pages/pc/system-notice/detail/Detail.vue'),
                meta: {
                    title: '通知详情',
                    parentMenu: '/system-notice'
                }
            }
        ]
    }
];
