<template>
    <div class="pc-header-host">
        <div class="header-left">
            <ProjectInfo />
        </div>
        <div class="header-right">
            <span class="mr-8 user-name">欢迎{{ company }}</span>
            <PersonCenter />
        </div>
    </div>
</template>
<script lang="ts" setup>
import ProjectInfo from '@/pages/layout/header/ProjectInfo.vue';
import PersonCenter from '@/pages/layout/header/PersonCenter.vue';
import useStore from '@/pinia';

const { userInfo } = useStore();
const company = computed(() => userInfo?.getUserInfo?.company);
</script>
<style lang="less" scoped>
.pc-header-host {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #1890ff;
    height: 60px;
    color: #fff7e6;
    .header-right {
        .custom-badge {
            display: flex;
            align-items: center;
            margin-right: 40px;
        }
        .user-name {
            font-size: 16px;
        }
    }

    .header-left,
    .header-right {
        display: flex;
        align-items: center;
    }

    .header-left {
        flex: 1;
        .project-logo {
            display: inline-block;
            height: 28px;
            margin: 0 8px;
        }

        .project-name {
            font-size: 16px;
            letter-spacing: 0.05em;
            //color: @major-color;
        }
    }
}
</style>
