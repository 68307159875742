import Layout from '@/pages/mobile-layout/Layout.vue';

export const userCenter = [
    {
        path: '/user-center',
        component: Layout,
        name: 'user-center',
        meta: {
            title: '用户中心',
            parentMenu: '/'
        },
        redirect: '/user-center/user-center-index',
        children: [
            {
                path: 'user-center-index',
                name: 'user-center-index',
                component: () => import('@/pages/mobile/user-center/index/Index.vue'),
                meta: {
                    title: '用户中心',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'switch-account',
                name: 'switch-account',
                component: () =>
                    import('@/pages/mobile/user-center/switch-account/SwitchAccount.vue'),
                meta: {
                    title: '切换账号',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-password-inner',
                name: 'update-password-inner',
                component: () =>
                    import('@/pages/mobile/user-center/update-password/UpdatePassword.vue'),
                meta: {
                    title: '修改密码',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-password-success',
                name: 'update-password-inner-success',
                component: () =>
                    import('@/pages/mobile/user-center/update-success/UpdateSuccess.vue'),
                meta: {
                    title: '修改密码 - 成功',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-phone-inner',
                name: 'update-phone-inner',
                component: () => import('@/pages/mobile/user-center/update-phone/UpdatePhone.vue'),
                meta: {
                    title: '修改手机',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-phone-success',
                name: 'update-phone-inner-success',
                component: () =>
                    import('@/pages/mobile/user-center/update-success/UpdateSuccess.vue'),
                meta: {
                    title: '修改手机 - 成功',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-info-inner',
                name: 'update-info-inner',
                component: () => import('@/pages/mobile/user-center/update-info/UpdateInfo.vue'),
                meta: {
                    title: '修改账户信息',
                    parentMenu: '/user-center'
                }
            },
            {
                path: 'update-info-success',
                name: 'update-info-inner-success',
                component: () =>
                    import('@/pages/mobile/user-center/update-success/UpdateSuccess.vue'),
                meta: {
                    title: '修改账户信息 - 成功',
                    parentMenu: '/user-center'
                }
            }
        ]
    }
];
