<script setup lang="ts">
import { HomeFilled, LayoutFilled, DownOutlined } from '@ant-design/icons-vue';
import useRouterCommon from '@/hooks/useRouterCommon';

const { push } = useRouterCommon();

const menu = [
    {
        label: '申请授权',
        key: 'apply-auth',
        to: '/business/apply-auth-list'
    },
    {
        label: '下载授权',
        key: 'download-auth',
        to: '/business/download-auth-list'
    },
    {
        label: '资料下载',
        key: 'file-manage',
        to: '/business/file-manage-list'
    },
    {
        label: '授权信息报表查询',
        key: 'report-manage',
        to: '/business/report-manage-list'
    }
];
</script>

<template>
    <div class="pc-menu-host">
        <a-dropdown class="dropdown">
            <a class="ant-dropdown-link" @click.prevent="push('/system-notice/list')">
                <HomeFilled />
                主页
            </a>
        </a-dropdown>
        <a-dropdown class="dropdown">
            <a class="ant-dropdown-link" @click.prevent>
                <LayoutFilled />
                业务功能
                <DownOutlined class="arrow" />
            </a>
            <template #overlay>
                <a-menu>
                    <a-menu-item v-for="item in menu" :key="item.key">
                        <a href="javascript:;" @click="push(item.to)">{{ item.label }}</a>
                    </a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
    </div>
</template>

<style scoped lang="less">
.pc-menu-host {
    height: 38px;
    display: flex;
    padding: 0 40px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: var(--fff, #fff);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

    .ant-dropdown-link {
        color: var(--65, rgba(0, 0, 0, 0.65));

        .arrow {
            font-size: 10px;
        }
    }

    :deep(.anticon) {
        color: rgba(0, 0, 0, 0.45);
        margin-top: 4px;
    }
}
</style>
