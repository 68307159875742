<template>
    <a-dropdown placement="bottomRight" :arrow="{ pointAtCenter: true }">
        <div class="pc-pre-user-center">
            <a-avatar :src="avatar" />
            <DownOutlined class="pre-down-icon" />
        </div>
        <template #overlay>
            <a-menu>
                <a-menu-item v-for="item in menu" :key="item.id">
                    <template #icon>
                        <icon-font :type="item.icon" />
                    </template>
                    <a href="javascript:;" @click="push(item.to)">{{ item.name }}</a>
                </a-menu-item>
                <a-sub-menu
                    v-if="userList && userList.length > 1 && userInfo.getLoginType === 'smsLogin'"
                    key="sub1"
                    title="切换用户"
                >
                    <template #icon>
                        <icon-font type="icon-yonghu" />
                    </template>
                    <a-menu-item
                        v-for="item in userList"
                        :key="item"
                        :class="username === item ? 'on' : ''"
                        @click="switchUser(item)"
                        ><CheckOutlined v-if="item === username" class="mr-4" />
                        <span v-else class="block"></span>{{ item }}</a-menu-item
                    >
                </a-sub-menu>
                <a-menu-divider />
                <a-menu-item>
                    <template #icon>
                        <icon-font type="icon-tuichu" />
                    </template>
                    <a href="javascript:;" @click="logout">退出</a>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script lang="ts" setup>
import { DownOutlined, CheckOutlined } from '@ant-design/icons-vue';
import avatar from '@/assets/pc/images/avatar.jpg';
import usePersonCenter from '@/pages/layout/hooks/usePersonCenter';
import { postLoginOutHttp } from '@/common/http/request/request-method/login/login';

const { userInfo, queryUserList, push, userList, menu, username, switchUser } =
    usePersonCenter('pc');

const logout = async () => {
    try {
        await postLoginOutHttp({
            refreshToken: userInfo.getRefreshToken,
            accessToken: userInfo.getAccessToken
        });
        push('/login');
    } catch (e) {
        console.log(e);
    }
};

onMounted(() => {
    if (!userInfo.getUserList || !userInfo.getUserList.length) queryUserList();
});
</script>

<style lang="less" scoped>
.pc-pre-user-center {
    display: flex;
    align-items: center;
    cursor: pointer;

    .ant-avatar {
        border: 1px solid #fff;
        background: transparent;
        margin-right: 8px;
    }

    .toggle-role-icon {
        margin-right: 10px;
        cursor: pointer;
    }

    .pre-user-info {
        margin-left: 10px;
        margin-right: 5px;
        vertical-align: middle;
    }
    .pre-user-info-with-dep {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        margin-right: 5px;

        .user-name {
            font-size: 14px;
        }
    }
    .pre-down-icon {
        font-size: 14px;
    }
}
</style>
