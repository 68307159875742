import MD5 from 'crypto-js/md5';

const key = 'ixjh456mks34jus23';

/**
 * 4位随机码
 */
const fourHex = (): string => {
    let i = 0;
    let str = '';
    let random = 0;
    const aryNum = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < 4; i++) {
        random = parseInt((Math.random() * 16).toString(), 10);
        str += aryNum[random];
    }
    return str;
};

/**
 * 当前时间转换为16进制取后10位
 */
const timestamp = () => {
    const timeHex = new Date().getTime().toString(16);
    const hex = timeHex.substring(timeHex.length - 10, timeHex.length);
    // 不够位数补0
    return hex + (10 ** (10 - hex.length)).toString().replace('1', '');
};

/**
 * 截取后四位验证码
 */
const checkCode = (md5: string) => {
    let num = 0;
    md5.split('').forEach((v, i: number) => {
        num += parseInt(v, 16) * (i + 1);
    });
    const text = `0000${num.toString(16)}`;
    return text.substring(text.length - 4);
};

export interface EncryptionOptions {
    method: string;
    url: string;
    body: any;
    token: string;
}

export const getFingerPrint = ({ method, url, body, token }: EncryptionOptions) => {
    const preTimestamp = timestamp();
    const preFourHex = fourHex();
    let text = `${method}||${encodeURI(
        url
    )}||${body}||${token}||${preTimestamp}||${preFourHex}${key}`;
    text = MD5(text).toString() + preTimestamp + preFourHex;

    text += checkCode(text);

    return text;
};
