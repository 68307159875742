import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';

const useRouterCommon = () => {
    const router = useRouter();
    const route = useRoute();

    const back = () => {
        router.back();
    };

    const go = (param: number) => {
        router.go(param);
    };

    const push = (params: RouteLocationRaw) => {
        router.push(params);
    };

    const replace = (params: RouteLocationRaw) => {
        router.replace(params);
    };

    return {
        back,
        go,
        push,
        router,
        route,
        replace
    };
};

export default useRouterCommon;
