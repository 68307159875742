/* 登录接口 */
import { httpService } from '@/common/http/api/httpService';
import { RequestPaths } from '@/common/http/request/requestPaths';

const { userCenterApi } = new RequestPaths();

export const postUpdateUserInfoHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.updateUserInfo, params);
};

// 查询用户列表
export const postQueryUserListHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.queryUserList, params);
};

// 查询用户信息
export const postQueryUserInfoHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.queryUserInfo, params);
};

// 修改手机号 密码验证
export const postUpdateMobileByPwdHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.updateMobileByPwd, params);
};

// 修改手机号 短信验证
export const postUpdateMobileBySmsHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.updateMobileBySms, params);
};

// 切换用户
export const postSwitchUserHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.switchUser, params);
};

// 校验原手机号
export const postCheckOriginMobileHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.checkOriginMobile, params);
};

// 校验原密码
export const postCheckOriginPwdHttp = (params: any): Promise<any> => {
    return httpService.post(userCenterApi.checkOriginPwd, params);
};
