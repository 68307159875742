import { computed, ComputedRef } from 'vue';

import VenusLogo from '@/assets/pc/images/venus-logo-max.png';
import LeadSecLogo from '@/assets/pc/images/leadsec-logo-max.png';
import HzLogo from '@/assets/pc/images/hz-logo-max.png';

interface ReturnType {
    currentSystemInfo: ComputedRef<{
        loginLogo: ImageBitmap;
        companyName: string;
        phone: string;
    }>;
    APP_ID: string;
    isHz: ComputedRef<boolean>;
    isVenus: ComputedRef<boolean>;
    isLeadSec: ComputedRef<boolean>;
}

const useSystemInfo = (): ReturnType => {
    const APP_ID = import.meta.env?.VITE_APP_ID ?? 'venus';

    const CLIENT_URL = import.meta.env?.VITE_BASE_URL;

    const mode = import.meta.env?.VITE_APP_TITLE === 'test';

    const APP_ID_INFO = {
        venus: {
            companyName: '启明星辰',
            loginLogo: VenusLogo,
            phone: '400-624-3900'
        },
        hz: {
            companyName: '杭州合众',
            loginLogo: HzLogo,
            phone: '400-672-8900'
        },
        leadsec: {
            companyName: '网御星云',
            loginLogo: LeadSecLogo,
            phone: '400-810-7766'
        }
    };

    const currentSystemInfo = computed(() => (APP_ID_INFO as any)[APP_ID]);

    const isHz = computed(() => APP_ID === 'hz');
    const isVenus = computed(() => APP_ID === 'venus');
    const isLeadSec = computed(() => APP_ID === 'leadsec');

    return {
        currentSystemInfo,
        APP_ID,
        isHz,
        isLeadSec,
        isVenus
    };
};

export default useSystemInfo;
