<template>
    <a-config-provider
        :transformers="[legacyLogicalPropertiesTransformer]"
        :locale="zhCN"
        :theme="{
            hashed: false,
            token: {
                colorPrimary: '#1890FF',
                colorLink: '#1890FF',
                borderRadius: 2
            }
        }"
    >
        <router-view />
    </a-config-provider>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { legacyLogicalPropertiesTransformer } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import { onBeforeUnmount } from 'vue';
import { throttle } from '@/utils/common';
import useStore from '@/pinia';

const { deviceInfo } = useStore();

// const { handleToChangeDevice } = deviceStore();

const resizeChange = () => {
    if (document.documentElement.clientWidth > 750) {
        // 默认设置当屏幕宽度 > 750 时，为PC端
        deviceInfo.updateDeviceInfo('pc');
    } else {
        // 默认设置当屏幕宽度 <= 750 时，为移动端
        deviceInfo.updateDeviceInfo('mobile');
    }
};
// 节流处理
const throttleResize = throttle(resizeChange, 200);
throttleResize();
window.addEventListener('resize', throttleResize, false);
onBeforeUnmount(() => {
    window.removeEventListener('resize', throttleResize, false);
});

dayjs.locale('zh-cn');
</script>

<style scoped></style>
