import { nextTick, ref } from 'vue';

const scrollTarget = ref(null);
const operationTarget = ref(null);
const defaultHeight = ref(0);

const getCurrentHeight = () => {
    const { clientHeight } = scrollTarget.value;
    // 不等于且小于当前的内容高 赋值 说明高度增加 进行滚动
    if (clientHeight > defaultHeight.value && defaultHeight.value !== clientHeight) {
        operationTarget.value.scrollTop = clientHeight;
        defaultHeight.value = clientHeight;
    }
};

const resizeObserver = new ResizeObserver(() => {
    getCurrentHeight();
});

export default {
    mounted(el: any, binding: any) {
        nextTick(() => {
            // 获取对应DOM
            const scrollTargetEle = el.querySelector(binding.value.listenerTarget);
            const operationTargetEle = el.querySelector(binding.value.target);
            defaultHeight.value = scrollTargetEle.clientHeight;
            scrollTarget.value = scrollTargetEle;
            operationTarget.value = operationTargetEle;
            resizeObserver.observe(scrollTargetEle);
        });
    },
    // 绑定元素的父组件卸载前调用
    beforeUnmount() {
        resizeObserver.disconnect();
    }
};
