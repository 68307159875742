import JSEncrypt from 'jsencrypt';

const publicKeyDev =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhz1i2hrtj5XwHmyiKNySjPG4T3xs5Dj/snT6JLocDNelu4hfIqp+T/6HOq+fw0tTMgQoIs5wBCBrcdeju5VeZR6+XfOUlVc+hinAHfQBe4ifVsZbLHSfwSzW3ffxfpnGMmQpkQBEAOxAIo2u26GjXB4/VAmkOjgMxWshLrwtPqVa1f/jBQ3ly5GIGEmHXJhGq+fclx/hwpPng6WYHS3cfz36jYzbxZJ1ArNIWIaCpty2KNUk/8tOV13TiOEqG7ZIj3ZijDS133EAbxKO/dmAwUOJGTv7U/lBpK2ibT8VD5bjAD/2ssB7DWLJFaSZ6DFdgHHCWZLtoJmr9TwQpXyZzwIDAQAB';

const encrypt = new JSEncrypt();
const privateKey = encrypt.getPrivateKey(); // 获取私钥
const publicKey = encrypt.getPublicKey(); // 获取公钥

// 加密aes密钥
export const setEncryptedKey = (message: string) => {
    // const publicKey = await getPublicKey();
    // console.log('rsa文件中的publicKey', publicKeyDev);
    const jsencrypt = new JSEncrypt();
    // 设置公钥 公钥是由后端返回的
    jsencrypt.setPublicKey(publicKeyDev);
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    return jsencrypt.encrypt(message);
};

export const getEncryptedKey = (message: string) => {
    // const publicKey = await getPublicKey();
    // console.log('rsa文件中的publicKey', publicKeyDev);
    const jsencrypt = new JSEncrypt();
    // 设置公钥 公钥是由后端返回的
    jsencrypt.setPrivateKey(
        'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC4Kphz3s0Qlwo+ZG54EsmL8vF3V0sS1dZAfeGiedD1jfClrUBg3a5qp/P7o561/w6rYdxoSMfjck92tVmyOfC+6kXl86xAP7p4UPyKp4prMAkTntG7RM5MT9N463sjsHRXTKEMgWUVgKjT9esTp8W7rFx1O+WBOzjogcpBoyxVl8hCDtiLGbdkHVrgW8ruhHFpo/8rfFxjmeX8JSX7OyhfwVSg3S1wV2XMx33G6e0kMrsi3eNEZI03gCqZAlNVFb3YOs2T+j5GcLPQLFR8PYNFbBePPv8NvA3xjmFAN+FZBTTbqnA38fCW+f53dZwmPTekq6PnzUL1MG3VJy5+LLhhAgMBAAECggEAQCKdWuTBV0CyUY4aJnisda9SRfRBcbrEVUtBU5rGmW0n3Bqcq98niX8Z6Y1OjSTYz/hRkIOgEOZTJAz9wqKI44OkefNFDk7jOllByWuVOcTNtGZ/tY69pTe7doXTKVee/HP0Ne2t7TIUGVSv2/V2V+grfqwwPbQJGndwnzEbFGcizoTa+agyyleYS5T7UfRiYELd+VhBjW8ORb5BypYkizYCdNan8fYj5fqUwDv4HV1cVR3QRR84yc3I1xOGrTtlH/m3e08AHlv52fOOBZRP4pgYrV2qg3GvkXBdgTeWiPapZc1EAh2J7rb/9OiC3gt+p0kvOGwlK7oSrInE/H+zAQKBgQDnRSEopDDKqGAp1Y9qmRTzWsMwsc1xOU3cvPE1H0Y7noVF+DrWwYeTf5Tgt34+gz2+SbeFtcC64ABZANHqVyY1uVpSCKc89UIehybkqJ2urPPAXKHUwstNoMEXqVGI1FVHfyMIz7EilTAPrSnSOqWwM+/m/9m38hCn+5cMbzDgkQKBgQDL3AiUUHJE1bwyhVO64EeitQ045cPtwoBmM8/b5Ze5LRxzwc2jQLIqfIEtv0qQ/YYGEwP1lv2rOorcKYgd4EQ8lTRHIMg3J0wrq0ljYsHyVQ6+N+pk18xXbSW7cbcXpUfpAQ9gJU4vjmcd6a++Z9jY/RcmFwkBVtT/We8N92BC0QKBgQCwekYVV0WMP/QxuC+abnpYQVhVXjihP9M0sFF38geMEykr/YIYwtGMMn0PBbamSUviWypaNBmAANMDF+cXZa1YrbpIgyXrFFSCKyv4rNq2om/WyFkNnB+7ewId5iy7txvkVRYM8UAAXcuxCrixHeO1k8xrf61J+sPPC+N1r5uioQKBgQCqTjGkpegwdVZ55kRi1EdEOHjJVbnvARR/TT2S+SVdf8BLPFbs0XmGYOkSLMLAievTW0Ntgix9RbUH5HJgcyDOc99upIZe/Sd3O+tdaf2Y01Izu0bH5DhDuV1SUNeim2ON1mLf8Yp11EN1C+rT6LGR5S3XllrhkYbzpFA/nLAAYQKBgC00n99aShrrYRoJ15e0+6g5l9oa0VQnQ7x3kslk/Bvysy9xuaSxRxAiFFMfZ1c7cDMts0rM4T1bBe3w3HVOIq27fJlFg1LO4jiZO7bRqUab5mqqcdOScrkAumtZ2s+8x19xiEJKk+Zjju76Mlgbc9vYun9+fcvVLX52Afphlboq'
    );
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    return jsencrypt.decrypt(message);
};
