import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router';
import useStore from '@/pinia';
import { pc } from '@/router/pc/pc';
import { mobile } from '@/router/mobile/mobile';
import { isPc } from '@/utils/common';

export const constantRoutes: RouteRecordRaw[] = isPc() ? [...pc] : [...mobile];

// 导出路由
const router = createRouter({
    history: createWebHistory('/'),
    routes: constantRoutes,
    linkExactActiveClass: 'active-route-link'
});

// 白名单list path 集合
const whiteRoutesPathList = [
    '/register-success',
    '/login',
    '/register',
    '/reset-password',
    '/update-password',
    '/update-success',
    '/reset-password-input'
];
router.beforeEach((to: RouteLocationNormalized) => {
    const { userInfo } = useStore();
    const token = userInfo.getAccessToken;

    if (to.meta?.title) {
        document.title = `${to.meta.title} | 客服服务中心`;
    }

    const isWhitePath: boolean = whiteRoutesPathList.includes(to.path);
    if (isWhitePath || token) {
        return true;
    }

    if (!token) {
        return { path: '/login', redirect: '/login' };
    }
    return true;
});

// 设置全局后置钩子
router.afterEach(() => {
    if (!isPc()) window.scrollTo(0, 0); // 每次路由变化后滚动到顶部
});

export default router;
