/* 登录接口 */
import { httpService } from '@/common/http/api/httpService';
import { RequestPaths } from '@/common/http/request/requestPaths';

const { loginApi, common } = new RequestPaths();

export const postLoginHttp = (params: any): Promise<any> => {
    return httpService.post(loginApi.login, params);
};

// 退出登录
export const postLoginOutHttp = (params: any): Promise<any> => {
    return httpService.post(loginApi.loginOut, params);
};

// 获取验证码
export const postCaptchaCodeHttp = (): Promise<any> => {
    return httpService.post(common.captchaCode);
};

// 获取验证码
export const postSmsCodeHttp = (params: any): Promise<any> => {
    return httpService.post(common.sms, params);
};

// 刷新token 验证是否过期
export const postRefreshTokenHttp = (params: any): Promise<any> => {
    return httpService.post(common.refreshToken, params);
};

// 发送邮件验证码
export const postSendEmailCodeHttp = (params: any): Promise<any> => {
    return httpService.post(common.emailCode, params);
};

// 重置密码 手机验证
export const postResetPasswordByPhoneHttp = (params: any): Promise<any> => {
    return httpService.post(loginApi.resetPwdByPhone, params);
};

// 重置密码 邮箱验证
export const postResetPasswordByEmailHttp = (params: any): Promise<any> => {
    return httpService.post(loginApi.resetPwdByEmail, params);
};

// 修改密码
export const postUpdatePasswordHttp = (params: any): Promise<any> => {
    return httpService.post(loginApi.updatePwd, params);
};
