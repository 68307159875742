import { createApp, App } from 'vue';
import { createFromIconfontCN } from '@ant-design/icons-vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import Vant from 'vant';
import AppMain from './App.vue';
import router from './router';
import appDirectives from './directives/appDirectives';
import 'amfe-flexible';
import 'vant/lib/index.css';

import '@/styles/shared.less';

export const IconFont = createFromIconfontCN({
    scriptUrl: ['//at.alicdn.com/t/c/font_3270857_ncvu3tcdv7.js'] // ec
});
const pinia = createPinia();

const app: App = createApp(AppMain);
pinia.use(piniaPluginPersistedstate);

// 性能报告收集
app.use(router).use(pinia).use(appDirectives).use(Vant).component('IconFont', IconFont);
app.mount('#app');

// app.config.errorHandler = (err, instance, info) => {
//     // 处理错误，例如：报告给一个服务
//     console.log(err, instance, info);
// };
