import { formateMoney } from '@/utils/formate';

export function convertCurrency(num: string) {
    let strOutput = '';
    let strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
    num += '00';
    const intPos = num.indexOf('.');
    if (intPos >= 0) num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
    strUnit = strUnit.substr(strUnit.length - num.length);
    for (let i = 0; i < num.length; i++)
        strOutput +=
            '零壹贰叁肆伍陆柒捌玖'.substr(Number(num.substr(i, 1)), 1) + strUnit.substr(i, 1);
    return strOutput
        .replace(/零角零分$/, '整')
        .replace(/零[仟佰拾]/g, '零')
        .replace(/零{2,}/g, '零')
        .replace(/零([亿|万])/g, '$1')
        .replace(/零+元/, '元')
        .replace(/亿零{0,3}万/, '亿')
        .replace(/^元/, '零元');
}

export const isInteger = (value: string | number) => {
    return typeof +value === 'number' && +value % 1 === 0;
};

export const coverMillimeter = (num: string | number) => {
    return `${num ?? '-'}`.replace(/\d+/, (n) => {
        // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
            return `${$1},`;
        });
    });
};

export function getIntergerZero(value: string | null | undefined, useNullValue = false): string {
    // 如果是0显示-
    if (!value || +value === 0) return value;
    // 是否是整数
    if (isInteger(value)) {
        return coverMillimeter(String(parseInt(`${value}`, 10)));
    }
    // 最终进行千分位操作
    return coverMillimeter((+value).toFixed(2));
}

// 把0 处理为-

export const transformZeroToLine = (value: number | string): string => {
    return !value ? '-' : `${value}`;
};

/* 更新数据 */
export const updateLabelValues = (
    labelKey: string,
    valueKey: string,
    target: any,
    setDefault = true,
    defaultOptions = {
        dictName: '全部',
        dictKey: null as any
    } as any
) => {
    if (!target) return [];
    setDefault && target.unshift(defaultOptions);

    return target.map((item: { [x: string]: any }) => {
        return {
            label: item[labelKey],
            value: item[valueKey]
        };
    });
};

// 对指定值进行千分位并补0

export const updateAmount = (formState: any, array: Array<any>) => {
    for (const key in formState) {
        if (array.includes(key)) {
            formState[key] = formateMoney(formState[key]);
        }
    }
};

// 转换input value
export const updateTargetToNull = (
    formState: any,
    key: string,
    data: any[],
    mapKey = 'dictKey'
) => {
    const isIncludes = data
        .map((item) => (item as any)[mapKey])
        .filter((item) => item)
        .includes(formState[key]);
    return isIncludes ? formState[key] : null;
};

export const isPc = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    return !isMobile;
};

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export const debounce = (func, wait = 1000, immediate = true) => {
    let timer;
    return function () {
        const context = this;
        const args = arguments;
        if (timer) clearTimeout(timer);
        if (immediate) {
            const callNow = !timer;
            timer = setTimeout(() => {
                timer = null;
            }, wait);
            if (callNow) func.apply(context, args);
        } else {
            timer = setTimeout(() => {
                func.apply(context, args);
            }, wait);
        }
    };
};
/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 使用表时间戳，在时间段开始的时候触发 2 使用表定时器，在时间段结束的时候触发
 */
export const throttle = (func: any, wait = 1000, type = 1) => {
    let previous = 0;
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        if (type === 1) {
            const now = Date.now();

            if (now - previous > wait) {
                func.apply(context, args);
                previous = now;
            }
        } else if (type === 2) {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    func.apply(context, args);
                }, wait);
            }
        }
    };
};
