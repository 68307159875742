<template>
    <div class="pc-project-info-host">
        <img
            class="project-logo"
            :src="currentSystemInfo.loginLogo"
            :alt="currentSystemInfo.companyName"
        />
        <h1>客户服务中心</h1>
    </div>
</template>
<script lang="ts" setup>
import useSystemInfo from '@/hooks/useSystemInfo';

const { currentSystemInfo } = useSystemInfo();
</script>
<style lang="less" scoped>
.pc-project-info-host {
    display: flex;
    align-items: center;
    height: 60px;
    margin-left: 15px;
    .project-logo {
        display: inline-block;
        height: 36px;
        margin: 0 8px;
    }
    h1 {
        font-size: 18px;
        font-weight: 600;
        padding-top: 4px;
    }
}
</style>
