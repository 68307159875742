import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'ant-design-vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as he from 'he';

import { showToast } from 'vant';
import { HttpRequestFingerPrint } from './httpRequestFingerPrint';
import { ConvertHumpToLine, ConvertLineToHump } from '@/utils/nameConversion';
import router from '@/router';
import { removeUser } from '@/utils/auth';
import useStore from '@/pinia';
import { setEncryptedKey } from '@/utils/rsa';
import { getRandomSecret, setEncryptCode } from '@/utils/aes';

const errorCode = ['401'];
/*
 * 21-11-10-18 授权码无效
 * 21-11-10-22 序列号无效
 * 21-11-10-23 密码格式
 * 21-11-10-27 序列号已被占用
 * 21-11-10-38 验证码错误
 * */
const errorCodeList = [
    '21-11-10-18',
    '21-11-10-22',
    '21-11-10-23',
    '21-11-10-38',
    '21-11-10-27',
    '21-11-10-39',
    '21-11-10-43',
    '21-11-10-48'
];

const isRegister = window.location.pathname.indexOf('register') > 0;

export class HttpInterceptors {
    private axiosInstance: AxiosInstance;

    private httpRequestFingerPrint: HttpRequestFingerPrint;

    constructor(config: AxiosRequestConfig) {
        this.axiosInstance = axios.create(config);
        this.httpRequestFingerPrint = new HttpRequestFingerPrint();
        this.initInterceptors();
    }

    // 初始化拦截器
    private initInterceptors() {
        /** 添加请求拦截器 * */
        this.axiosInstance.interceptors.request.use(
            async (request: any) => {
                const { userInfo } = useStore();
                const token = userInfo.getAccessToken;
                const isLogin = userInfo.getIsLogin;
                if (isLogin) {
                    await userInfo.refreshNewToken();
                    request.headers.authorization = `Bearer ${token}`;
                }

                if (import.meta.env?.VITE_APP_ID) {
                    request.headers.appId = import.meta.env?.VITE_APP_ID;
                }

                request.url = (request.url as string)?.replace(/[?&][\w]*=/g, (query) =>
                    ConvertHumpToLine(query)
                );

                if (!(request.data instanceof FormData)) {
                    const key = getRandomSecret();
                    request.headers['Secret-Key'] = setEncryptedKey(key);
                    const secretBody = setEncryptCode(
                        JSON.stringify(request.data)?.replace(/"\w*":/g, (params) =>
                            ConvertHumpToLine(params)
                        ),
                        key
                    );
                    request.data = {
                        secretBody
                    };
                }

                // if (request.data && !(request.data instanceof FormData)) {
                //     request.data = JSON.parse(
                //         JSON.stringify(request.data)?.replace(/"\w*":/g, (params) =>
                //             ConvertHumpToLine(params)
                //         )
                //     );
                // }
                request.headers = Object.assign(
                    request.headers,
                    this.httpRequestFingerPrint.requestIntercept(request)
                );
                return Promise.resolve(request);
            },
            (error) => Promise.reject(error)
        );

        /** 添加响应拦截器  * */
        this.axiosInstance.interceptors.response.use(
            (response) => {
                if (response.data?.data && response.data.code !== 200) {
                    this.recursionData(response.data);
                    response.data = JSON.parse(
                        JSON.stringify(response.data).replace(/"\w*":/g, (body) =>
                            ConvertLineToHump(body)
                        )
                    );
                }

                return Promise.resolve(response?.data);
            },
            (error) => {
                // 21-11-10-37 3个月 提示密码过期
                const { deviceInfo } = useStore();
                if (error?.response?.data?.code === '21-11-10-37') {
                    router.push({
                        name: 'update-password',
                        query: {
                            type: 'date'
                        }
                    });

                    return Promise.reject((error.data as any).message);
                }

                // 21-11-10-23 简单密码提示
                if (error?.response?.data?.code === '21-11-10-23') {
                    router.push({
                        name: 'update-password',
                        query: {
                            type: 'pwd'
                        }
                    });

                    return Promise.reject((error.data as any).message);
                }

                if (errorCode.includes(error?.response?.data?.code)) {
                    const { userInfo } = useStore();
                    removeUser();
                    userInfo.clearUserInfo();
                    router.push('/login');
                    if (deviceInfo.getDevice === 'pc') {
                        message.error('登录过期,请重新登录');
                    } else {
                        showToast('登录过期,请重新登录');
                    }
                    return Promise.reject(error);
                }

                if (error?.response?.data?.code === '403') {
                    if (deviceInfo.getDevice === 'pc') {
                        message.error('用户无权限,请联系管理员!');
                    } else {
                        showToast('用户无权限,请联系管理员!');
                    }

                    return Promise.reject(error);
                }

                const { userInfo } = useStore();
                const isLogin = userInfo.getIsLogin;

                if (errorCodeList.includes(error?.response?.data?.code)) {
                    // if (error?.request?.responseURL.indexOf('sendSmsCode') > -1) {
                    //     if (deviceInfo.getDevice === 'pc') {
                    //         message.error(error.response?.data?.message, 3);
                    //     } else {
                    //         showToast(error.response?.data?.message);
                    //     }
                    // }
                } else if (deviceInfo.getDevice === 'pc') {
                    message.error(
                        error.response?.data?.message ||
                            error.response?.data?.error ||
                            '请求超时！',
                        3
                    );
                } else {
                    showToast(
                        error.response?.data?.message || error.response?.data?.error || '请求超时！'
                    );
                }

                return Promise.reject(error);
            }
        );
    }

    getAxiosInterceptors(): AxiosInstance {
        return this.axiosInstance;
    }

    recursionData(data: any) {
        if (data && typeof data === 'object') {
            for (const key in data) {
                if (typeof data[key] === 'string') {
                    data[key] = he.decode(data[key]);
                }
                if (data[key] && typeof data[key] === 'object') {
                    this.recursionData(data[key]);
                }
            }
        }
    }
}
