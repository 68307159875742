export class AuthLocal {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    get() {
        const val = localStorage.getItem(this.name);
        return val && JSON.parse(val);
    }

    set(val: any) {
        localStorage.setItem(this.name, JSON.stringify(val));
    }

    remove() {
        localStorage.removeItem(this.name);
    }
}

const tokenInstance = new AuthLocal('token');
const userInstance = new AuthLocal('user');

export function getToken() {
    return tokenInstance.get();
}

export function setToken(token: string) {
    return tokenInstance.set(token);
}

export function removeToken() {
    return tokenInstance.remove();
}

export function getUser() {
    return userInstance.get();
}

export function setUser(user: any) {
    return userInstance.set(user);
}

export function removeUser() {
    return userInstance.remove();
}
