<script setup lang="ts">
import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue';

const router = useRouter();
const filterRoutes = ref([]);
// 根据路由生成面包屑数据
const generateBreadcrumb = (route: RouteLocationNormalizedLoaded): any[] => {
    const items: any[] = [];
    if (`${route.meta?.parentMenu}-list` === `/${route.name as string}`) {
        return [];
    }
    // 遍历 matched 数组中的每个路由记录
    route.matched.forEach((record) => {
        // 检查路由是否有 meta.title 属性，如果有则用它作为标题
        const { parentMenu, title, excludeSetTitle } = record.meta;
        const currentTitle =
            record.path.includes('form') && !!route.params.id && !excludeSetTitle
                ? '编辑表单'
                : title;
        // 构建面包屑项的路径，如果存在 children 路由且当前路由不是最后一个，则使用第一个 children 的路径
        items.push({
            title: currentTitle,
            path: record?.redirect || record.path,
            parentMenu,
            name: record.name
        });
    });

    items.forEach((item) => {
        if (item.name === route.name) {
            item.path = route.fullPath;
        }
    });
    return items.filter((item: any) => !!item?.parentMenu || item.path.includes('list'));
};

const updateBreadcrumb = () => {
    // 假设你有一个函数用于根据当前路由获取面包屑数据
    const currentRoute = router.currentRoute.value;
    filterRoutes.value = generateBreadcrumb(currentRoute);
};

watch(
    () => router.currentRoute.value,
    () => {
        updateBreadcrumb();
    },
    {
        immediate: true,
        deep: true
    }
);

onMounted(() => {
    updateBreadcrumb();
});

const handleClick = (item: any) => {
    router.push(item.path);
};
</script>

<template>
    <div class="pc-breadcrumb-host">
        <a-breadcrumb>
            <a-breadcrumb-item v-for="(item, index) in filterRoutes" :key="index">
                <span @click.stop="handleClick(item)">{{ item.title }}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<style scoped lang="less">
.pc-breadcrumb-host {
    padding: 20px 40px;
}
</style>
