import { defineStore, StateTree } from 'pinia';
import { postRefreshTokenHttp } from '@/common/http/request/request-method/login/login';

interface UserInfoState {
    userInfo: Record<string, any>;
    refreshToken: string;
    accessToken: string;
    appId: string;
    expireTime: string | number;
    loginTime: string;
    isLogin: boolean;
    resetInfo: object;
    userList: string | Array<any>;
    loginType: string;
    username: string;
    noticeDetail: string;
    chooseCodes: string | Array<any>;
    codeSource: string | object;
}

export const userInfoStore: any = defineStore('userInfo', {
    state: (): UserInfoState => ({
        userInfo: null,
        refreshToken: '',
        accessToken: '',
        appId: '',
        expireTime: '',
        loginTime: '',
        isLogin: false,
        resetInfo: {},
        userList: [],
        loginType: '',
        username: '',
        noticeDetail: '',
        chooseCodes: [],
        codeSource: {}
    }),
    getters: {
        getRefreshToken: (state: StateTree) => {
            return state.refreshToken;
        },
        getAccessToken: (state: StateTree) => {
            return state.accessToken;
        },
        getAppId: (state: StateTree) => {
            return state.appId;
        },
        getExpireTime: (state: StateTree) => {
            return state.expireTime;
        },
        getLoginTime: (state: StateTree) => {
            return state.loginTime;
        },
        getUserInfo: (state: StateTree) => {
            return state.userInfo;
        },
        getIsLogin: (state: StateTree) => {
            return state.isLogin;
        },
        getResetInfo: (state: StateTree) => {
            return state.resetInfo;
        },
        getUserList: (state: StateTree) => {
            return state.userList;
        },
        getLoginType: (state: StateTree) => {
            return state.loginType;
        },
        getUsername: (state: StateTree) => {
            return state.username;
        },
        getNoticeDetail: (state: StateTree) => {
            return state.noticeDetail;
        },
        getChooseCodes: (state: StateTree) => {
            return state.chooseCodes;
        },
        getCodeSource: (state: StateTree) => {
            return state.codeSource;
        }
    },
    actions: {
        clearUserInfo() {
            this.refreshToken = null;
            this.accessToken = null;
            this.userInfo = null;
            this.expireTime = null;
            this.loginTime = null;
            this.isLogin = false;
            this.resetInfo = {};
            this.userList = [];
            this.username = null;
        },
        clearNoticeDetail() {
            this.noticeDetail = '';
        },
        clearChooseCodes() {
            this.chooseCodes = [];
        },
        clearCodeSource() {
            this.codeSource = {};
        },
        async updateData(data: any) {
            if (!data) return;
            const { accessToken, refreshToken, loginTime, expireTime, loginType } = data;
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            this.expireTime = Number(expireTime) * 1000;
            this.loginTime = loginTime;
            this.userInfo = data;
            this.isLogin = true;
            this.loginType = loginType;
        },
        async updateToken(data: any) {
            if (!data) return;
            const { accessToken, refreshToken, expireTime } = data;
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            this.expireTime = Number(expireTime) * 1000;
            this.userInfo = data;
        },
        async updateUserInfo(params: any) {
            this.userInfo = params;
        },
        async refreshNewToken() {
            try {
                if (new Date().getTime() - Number(this.loginTime) > Number(this.expireTime)) {
                    const { data } = await postRefreshTokenHttp({
                        token: this.refreshToken
                    });
                    if (data.token) {
                        this.accessToken = data.token;
                    } else {
                        // 退出系统
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        async updateResetInfo(params: any) {
            this.resetInfo = params;
        },
        async updateUserList(params: any) {
            this.userList = params;
        },
        async updateUsername(params: any) {
            this.username = params;
        },
        async updateNoticeDetail(params: any) {
            this.noticeDetail = params;
        },
        async updateChooseCodes(params: any) {
            this.chooseCodes = params;
        },
        async updateCodeSource(params: any) {
            this.codeSource = params;
        }
    },
    persist: {
        storage: sessionStorage,
        paths: [
            'userInfo',
            'accessToken',
            'refreshToken',
            'expireTime',
            'loginTime',
            'isLogin',
            'resetInfo',
            'userList',
            'loginType',
            'username',
            'noticeDetail',
            'chooseCodes',
            'codeSource'
        ]
    }
});
