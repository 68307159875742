/**
 * @param name
 * @description 去除下划线 并全部转为大写
 */
export function ConvertLineToHump(name: string) {
    return name.replace(/_(\w)/g, (all, letter) => {
        return letter.toUpperCase();
    });
}

/**
 * @param name
 * @description 小驼峰转_格式  并转为全小写
 */

export function ConvertHumpToLine(name: string) {
    return name
        ?.replace(/([A-Z][A-Z]+)/g, (match) => {
            return match.substring(0, 1) + match.substring(1).toLowerCase();
        })
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase();
}

/**
 * @param name
 * @description 去除中划线  并转为全小写
 */

export function convertHumpToKebab(name: string) {
    return name.replace(/([A-Z])/g, '-$1').toLowerCase();
}
