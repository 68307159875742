import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

export const getRandomSecret = () => {
    return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex); // 16字节
};

// 加密函数
function encryptMessage(message: string, key: string) {
    const k = CryptoJS.enc.Utf8.parse(key);
    const msg = CryptoJS.enc.Utf8.parse(message);

    const cipherParams = CryptoJS.AES.encrypt(msg, k, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return cipherParams.toString();
}

// 解密函数
function decryptMessage(message: string, key: string) {
    const k = CryptoJS.enc.Utf8.parse(key);

    // 解密
    const decryptedParams = CryptoJS.AES.decrypt(message, k, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    // 将解密后的字节数组转换为字符串
    return decryptedParams.toString(CryptoJS.enc.Utf8);
}

export const setBase64Code = (message: string) => Base64.stringify(Utf8.parse(message));

// 获取加密结果
export const setEncryptCode = (message: string, key: string) => encryptMessage(message, key);

// 获取解密结果
export const getDecryptCode = (message: string, key: string) => decryptMessage(message, key);

// // 加密
// const encrypted = encryptMessage(message, secretKey, iv);
// console.log('加密后:', encrypted);
//
// // 解密
// const decrypted = decryptMessage(encrypted, secretKey, iv);
// console.log('解密后:', decrypted);
