import Layout from '@/pages/mobile-layout/Layout.vue';

export const business = [
    {
        path: '/business',
        component: Layout,
        name: 'business',
        meta: {
            title: '业务功能',
            parentMenu: '/'
        },
        redirect: '/business/apply-auth-list',
        children: [
            {
                path: 'apply-auth-list',
                name: 'apply-auth-list',
                component: () => import('@/pages/mobile/business/apply-auth/list/List.vue'),
                meta: {
                    title: '申请授权',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-form',
                name: 'apply-auth-form',
                component: () => import('@/pages/mobile/business/apply-auth/form/Form.vue'),
                meta: {
                    title: '申请授权',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-retry-form',
                name: 'apply-auth-retry-form',
                component: () =>
                    import('@/pages/mobile/business/apply-auth/retry-form/RetryForm.vue'),
                meta: {
                    title: '申请授权-重试',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-success',
                name: 'apply-auth-success',
                component: () => import('@/pages/mobile/business/apply-auth/success/Success.vue'),
                meta: {
                    title: '申请授权-成功',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-add-code',
                name: 'apply-auth-add-code',
                component: () => import('@/pages/mobile/business/apply-auth/add-code/AddCode.vue'),
                meta: {
                    title: '新增授权码',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-add-code-success',
                name: 'apply-auth-add-code-success',
                component: () => import('@/pages/mobile/business/apply-auth/success/Success.vue'),
                meta: {
                    title: '新增授权码-成功',
                    parentMenu: '/business'
                }
            },
            {
                path: 'apply-auth-add-code-error',
                name: 'apply-auth-add-code-error',
                component: () => import('@/pages/mobile/business/apply-auth/error/Error.vue'),
                meta: {
                    title: '新增授权码-失败',
                    parentMenu: '/business'
                }
            },
            {
                path: 'download-auth-list',
                name: 'download-auth-list',
                component: () => import('@/pages/mobile/business/download-auth/list/List.vue'),
                meta: {
                    title: '下载授权',
                    parentMenu: '/business'
                }
            },
            {
                path: 'download-auth-detail',
                name: 'download-auth-detail',
                component: () => import('@/pages/mobile/business/download-auth/detail/Detail.vue'),
                meta: {
                    title: '下载授权',
                    parentMenu: '/business'
                }
            }
        ]
    }
];
