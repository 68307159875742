import Layout from '@/pages/layout/Layout.vue';

export const business = [
    {
        path: '/business',
        component: Layout,
        name: 'business',
        meta: {
            title: '业务功能',
            parentMenu: '/'
        },
        redirect: '/business/apply-auth-list',
        children: [
            {
                path: 'apply-auth-list',
                name: 'apply-auth-list',
                component: () => import('@/pages/pc/business/apply-auth/List.vue'),
                meta: {
                    title: '申请授权',
                    parentMenu: '/business'
                }
            },
            {
                path: 'download-auth-list',
                name: 'download-auth-list',
                component: () => import('@/pages/pc/business/download-auth/List.vue'),
                meta: {
                    title: '下载授权',
                    parentMenu: '/business'
                }
            },
            {
                path: 'file-manage-list',
                name: 'file-manage-list',
                component: () => import('@/pages/pc/business/file-manage/List.vue'),
                meta: {
                    title: '资料下载',
                    parentMenu: '/business'
                }
            },
            {
                path: 'report-manage-list',
                name: 'report-manage-list',
                component: () => import('@/pages/pc/business/report-manage/List.vue'),
                meta: {
                    title: '授权信息报表查询',
                    parentMenu: '/business'
                }
            }
        ]
    }
];
