/* eslint-disable no-underscore-dangle */
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpInterceptors } from './httpInterceptors';

export type RequestMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';
const baseUrl = computed(() => (import.meta.env.DEV ? '' : import.meta.env.VITE_BASE_URL));
const currentSysTemBaseUrlInfo = computed(() => `${baseUrl.value}/cscOut`);

// 请求配置
export class HttpService {
    private static _instance: HttpService;

    private axios: AxiosInstance;

    // 获取axios实例
    private constructor(axiosInterceptors: HttpInterceptors) {
        this.axios = axiosInterceptors.getAxiosInterceptors();
    }

    static get instance(): HttpService {
        if (HttpService._instance) return HttpService._instance;
        this._instance = new HttpService(
            new HttpInterceptors({
                baseURL: currentSysTemBaseUrlInfo.value,
                timeout: 0 // 设置超时时间10s
            })
        );
        return this._instance;
    }

    get apiGatewayUri(): string {}

    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     * @param config
     */
    get<R = any, P = any>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .get(url, {
                    ...config,
                    params
                })
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     * post方法，对应post请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     * @param config 配置
     */
    post<R = any, P = any>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .post(url, params, config)
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     * put方法，对应put请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    put<R = any, P = any>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .put(url, params, config)
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     * delete方法，对应delete请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    delete<R = any, P = any>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .delete(url, {
                    ...config,
                    data: params
                })
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     * patch方法，对应patch请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    patch<R = any, P = any>(url: string, params: P, config?: AxiosRequestConfig): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .patch(url, params, config)
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    /**
     * request方法，动态请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    request<R = any, P = any>(
        url: string,
        method: string | RequestMethod,
        params?: P,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return new Promise((resolve, reject) => {
            this.axios
                .request({
                    method: method.toLocaleLowerCase(),
                    url,
                    data: params,
                    ...config
                })
                .then((res: R | any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }
}

export const httpService = HttpService.instance;
