import { App } from 'vue';
import drag from '@/directives/drag';
import scrollToBottom from '@/directives/scrollToBottom';

export default {
    install(app: App<Element>) {
        app.directive('drag', drag);
        app.directive('scrollToBottom', scrollToBottom);
    }
};
