import { getFingerPrint } from '@/utils/fingerPrint';

export class HttpRequestFingerPrint {
    token: string;

    urls: string[] = [];

    requestIntercept(req: any) {
        // 通过header头获取token
        this.token =
            (req.headers?.authorization || req.headers?.Authorization || '').replace(
                'Bearer ',
                ''
            ) || 'NO_AUTH_TOKEN';
        const { headers } = req;

        const requestBody = this.requestBody(req.data || '');

        const text = getFingerPrint({
            method: req.method.toLocaleUpperCase(),
            url: this.replaceUrl(req.url),
            body: requestBody,
            token: this.token
        });
        req.headers['Finger-Print'] = text;
        return headers;
    }

    private replaceUrl(url: string) {
        for (const i in this.urls) {
            url = url.replace(this.urls[i], '').replace(/^[/]/, '');
        }
        return url;
    }

    requestBody(body: any) {
        // 如果是文件上传
        if (body instanceof FormData) {
            return 'MULTIPART_FORM_DATA';
        }
        if (typeof body === 'string' || typeof body === 'number') {
            return body;
        }
        if (typeof body === 'object') {
            return JSON.stringify(body);
        }

        return '';
    }
}
