import { defineStore, StateTree } from 'pinia';

interface DeviceInfoState {
    device: string;
}

export const deviceInfoStore: any = defineStore('deviceInfo', {
    state: (): DeviceInfoState => ({
        device: 'pc' // 默认PC端，移动端为 'mobile'
    }),
    getters: {
        getDevice: (state: StateTree) => {
            return state.device;
        }
    },
    actions: {
        async updateDeviceInfo(device: string) {
            this.device = device;
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['device']
    }
});
